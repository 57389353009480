/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */


$toast_info: "data:image/svg+xml;base64,PHN2ZyBtZXRob2Q9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSJjb250ZW50IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgY2xhc3M9ImZlYXRoZXIgZmVhdGhlci1hbGVydC1jaXJjbGUiPjxjaXJjbGUgY3g9IjEyIiBjeT0iMTIiIHJkPSIxMCI+PC9jaXJjbGU+PHJlZnMgY2xhc3M9ImZlYXRoZXIgZmVhdGhlci1hbGVydC1jaXJjbGUiPjxsaW5lIHgxPSIxMiIgeTE9IjgiIHgyPSIxMiIgeTI9IjEyIiB4Mj0iMTIuMDEiIHkyPSIxNiIvPjwvcmVmPjwvc3ZnPg==";
$toast_waring: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-alert-triangle'%3E%3Cpolyline points='13 17 18 12 13 7'%3E%3C/polyline%3E%3Cpolyline points='6 17 11 12 6 7'%3E%3C/polyline%3E%3C/svg%3E";

.Toastify__toast-body{
	padding-left: 25px;
}
.Toastify__toast-body::before{
	content: '';
	width: 20px;
	height: 20px;
	position: absolute;
	top: calc(50% - 10px);
	left: 10px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}
.Toastify__toast--info .Toastify__toast-body::before{
	background-image: url('../images/icons/manual/toast-info.svg');
	color: #3498db !important; 

}
.Toastify__toast--success .Toastify__toast-body::before{
	background-image: url('../images/icons/manual/toast-success.svg');
	color: #07bc0c !important; 
}
.Toastify__toast--warning .Toastify__toast-body::before{
	background-image: url('../images/icons/manual/toast-warning.svg');
	color: #f1c40f !important; 
}
.Toastify__toast--error .Toastify__toast-body::before{
	background-image: url('../images/icons/manual/toast-error.svg');
	color: #e74c3c !important; 
}
.Toastify__toast{
	position: relative;
	background-color: #fff !important;
	color: #6e6b7b !important;
}
.Toastify__progress-bar--info {
	background: #3498db !important; 
}
.Toastify__progress-bar--success {
	background: #07bc0c !important; 
}
.Toastify__progress-bar--warning {
	background: #f1c40f !important; 
}
.Toastify__progress-bar--error {
	background: #e74c3c !important; 
}

.Toastify__close-button--info {
	color: #3498db !important; 
}
.Toastify__close-button--success {
	color: #07bc0c !important; 
}
.Toastify__close-button--warning {
	color: #f1c40f !important; 
}
.Toastify__close-button--error {
	color: #e74c3c !important; 
}

.ps__rail-y{
	width: 8px;
}
.ps__thumb-y{
	width: 6px !important;
	right: 1px;
}
.trade-setting-btn{
	width: 50%;
}
.reward-history-filter, .my-team-filter{
	display: flex;
}
.reward-history-filter > div, .my-team-filter > div{
	padding-right: 15px;
}
.reward-box p{
 margin-bottom: 5px;
}
.user-card-box img{
	width: 55px;
    padding: 5px;
    border: 1px solid;
    border-radius: 50px;
}
.user-card-box .main{
	border-bottom: 1px solid #ddd;
}
.user-card-box p{
	margin-bottom: 1px;
}

.Affiliate {background-color :#126CFC;
	h1,h2,h3,h4,h5,h6,p,label,span{
		color: #fff;
	}
}
.masterSearch{
	margin: 0 3rem;
}
.Engager {background-color :#00B050;
	h1,h2,h3,h4,h5,h6,p,label,span{
		color: #fff;
	}
}
.Accelerator {background-color :#00B0F0;
	h1,h2,h3,h4,h5,h6,p,label,span{
		color: #fff;
	}
}
.Achiever {background-color :#ED7D31;
	h1,h2,h3,h4,h5,h6,p,label,span{
		color: #fff;
	}
}
.Captain {background-color :#44546A;
	h1,h2,h3,h4,h5,h6,p,label,span{
		color: #fff;
	}
}
.Pioneer {background-color :#7030A0;
	h1,h2,h3,h4,h5,h6,p,label,span{
		color: #fff;
	}
}
.Innovator {background-color :#0070C0;
	h1,h2,h3,h4,h5,h6,p,label,span{
		color: #fff;
	}
}
.Gamechanger {background-color :#CC0066;
	h1,h2,h3,h4,h5,h6,p,label,span{
		color: #fff;
	}
}
.Legend {background-color :#BF9000;
	h1,h2,h3,h4,h5,h6,p,label,span{
		color: #fff;
	}
}
.radioGroup.direct_ref{
    border-right: 1px solid;
    div.row{
        line-height: 3 !important;
    }
}
.cycleOneShot.switchWrapper{
	font-size: 25px;
}
#CycleMode{
	margin: 10px 0;
}
.form-label {
    font-size: 1rem;
    color: #024073;
    font-weight: 500;
}
.notificationCard{
	position: relative;
}
.notificationCard .active{
	position: absolute;
    right: -7px;
    top: -7px;
    height: 15px;
    width: 15px;
    background: #f00;
    border-radius: 40px;
}
.slick-prev:before, .slick-next:before{
	font-size: 35px !important;
    opacity: 1 !important;
    color: #024073 !important;
}
.slick-prev, .slick-next{
    background: unset !important;
    opacity: 1 !important;
}
.img-robo-active-modal{
	width: 100px;
}
.sell-buy{
	position: absolute;
    height: auto;
    padding: 5px 20px;
    right: 10px;
    top: 10px;
}
@media (max-width: 767px) {
	.radioGroup.direct_ref{
        border-right: unset;
        border-bottom: 1px solid;
    }
	.masterSearch{
		margin: unset;
	}
	.reward-history-filter, .my-team-filter{
		display: block;
	}
	.reward-history-filter > div, .my-team-filter > div{
		padding: unset;
	}
	.TricksDetails{
		padding: unset !important;
	}
	.nav.navbar-nav.bookmark-icons{
		display: none;
	}
	.navbarUser{
		column-gap: 15px !important;
	}
	.navbarUser .d-flex p.d-m-none{
		display: none !important;
	}
	.TricksDetails .sec2 div {
		text-align: center;
		border-bottom: 1px solid #ddd;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.TricksDetails .sec3{
		display: block !important;
	}
	.trade-setting-btn{
		width: unset;
	}
}
