html{
    overflow-y: auto;
}
.bg-primary{
    background-color: #024073 !important;
    color: #fff !important;
}
.primary{
    background-color: #024073 !important;
    width: 100%;
    height: 40px;
    border-radius: 18px;
    border: 1px solid #024073;
    color: white;
 }
 .primary.red{
    background-color: #e71f1f !important;
    border: 1px solid #e71f1f;
 }
   .btn-outline-primary:hover,.btn-outline-primary {
    color: #024073 !important;
    border: 1px solid #024073 !important;
}
 .labelWidth{
    width: 100px;
 }
 .btn-danger {
    background-color: #ea5455 !important;
    border: 1px solid #ea5455 !important;
    height: 40px;
}
 .dander{
    background-color: red !important;
    width: 100%;
     height: 40px;
     border-radius: 18px;
     border: none;
     color: white;
 }
 p,label,b{
    color: #024073;
 }
 .btn-primary {
    border-color: #024073 !important;
    background-color: #024073 !important;
}
 .text-primary{
    color: #024073 !important;
 }
 .Sec1 .card-congratulations-medal.card {
     box-shadow: 1px 5px 15px 5px gray;
     margin: 5px 0;
     border-radius: 40px;
 }
 
 .dashboardImg {
     width: 50px;
     height: 50px;
 }
 .stocks {
     width: 60px;
     height: 60px;
 }
 .card_child{
    text-align: center;
 }
 .card_child p{
     margin-bottom: 5px;
     font-weight: 600;
 }
 .searchWrapper{
     position: relative;
 }
 .searchWrapper input {
     width: 100%;
     height: 40px;
     border: 1px solid #024073;
     border-radius: 10px;
     padding: 0 10px;
 }
 .searchWrapper button {
     width: 40px;
     height: 40px;
     border: 1px solid #024073;
     border-radius: 0 10px 10px 0;
     position: absolute;
     top: -5px;
     right: -5px;
 }
 .searchWrapper button svg {
    position: relative;
    top: -3px;
    right: 9px;
}
 .tobBtnWrapper button{
     width: 100%;
     height: 40px;
     border-radius: 18px;
     border: 1px solid #024073;
     background:#024073;
     color: white;
     font-weight: 500;
 }
 .Sec2 {
     height: 90px;
     display: flex;
     align-items: center;
 }
 .Sec3{
     margin: 0;
     border: 2px solid #024073;
 }
 
 .Sec3 .card {
     border: 1px solid gray;
     border-radius: 0;
 }

 html .content.app-content {
    padding: 60px 0 !important;
    padding-top: 0 !important;
}
/* .Sec3child2 p {
    margin-bottom: 0;
    font-weight: 800;
    font-size: 17px;
}
.Sec3child2 small{
    color: #7dc510;
} */
.Sec3child2_2 p{
    margin-bottom: 0;
    margin-left: 10px;
}

p.navbarTitle {
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 0;
}

/* modal code  */

.stocksWarningModal .modal-content {
    /* height: 60vh; */
}

.modalContent.mx-1 p {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
}

.navbar-floating .header-navbar-shadow {
    display: block;
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    z-index: 11;
}


/* .vertical-layout .main-menu .navigation a.active {
    background: linear-gradient(118deg, #f80000, rgba(115, 103, 240, 0.7));
} */

/* test */

.action-button-transition-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
.buttonWrapper button{
    width: auto;
    min-width: 100px;
}
  button {
    padding: 5px 20px;
    /* margin: 5px; */
    font-size: 16px;
  }
  .searchWrapper button{
    margin: 5px;
  }
  
  .action-button {
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .action-button-transition-container:hover .action-button {
    opacity: 1;
    transform: translateY(0);
  }
  
  .TradeSettings {
    max-width: 600px; /* Adjust this value to set your desired width */
  }

  .main-menu.menu-light .navigation {
    background: #024073;
    /* height: 88vh; */
}

  button.btn-primary,button.btn-primary:focus,.form-switch .form-check-input:checked,.form-switch .form-check-input:not(:checked) {
    background-color: #024073 !important;
    border:1px solid #024073 !important;
}
input[type="radio"]{
    border:1px solid #024073 !important;
}
button.btn-light{
    background-color: #fff !important;
    border:1px solid #024073 !important;
    color: black;
}

.backArrow{
    width:35px;
    height:35px;
    left: 2px;
    cursor: pointer;
    position: absolute;
}

/* Hide upper and lower arrow buttons */
::-webkit-scrollbar-button {
    display: none;
  }
  
  /* Reduce the width of the scrollbar */
  ::-webkit-scrollbar {
    width: 5px; /* Adjust as needed */
  }
  
  /* Style the scrollbar track */
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Adjust as needed */
  }
  
  /* Style the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background-color: #888; /* Adjust as needed */
  }
  img{
    cursor: pointer;
  }
  .primary {
    min-width: 161px;
    font-size: 14px;
}
.form-check-input:checked {
    background-color: #024073 !important;
    border-color: #024073 !important;
}
label,img,input[type='radio'],select,.pointer{
    cursor: pointer;
}
.labelMargin{
    margin-left: 4px;
}

input#startDate,input#endDate {
    width: 115px;
}
/* .pagination{
    background: #024073;
    color: white;
} */
.page-item.active .page-link {
    border-radius: 8rem;
    background-color: #024073 !important;
}
.page-item a.page-link {
    color: black;
}
.page-item.prev,.page-item.next {
    /* padding: 7.5px; */
}

.DirectReferralsWrapper img {
    padding: 5px;
    border: 1px solid;
    border-radius: 50px;
    width: 55px !important;
    height: 55px !important;
}
.radioGroup .row {
    line-height: 3;
}

.otpBtn{
    width: auto;
    min-width: 50px;
    padding: 10px;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
}